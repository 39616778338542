import React from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Layout, Seo } from "../../components";

const PrintingGuidePage = (props) => (
  <Layout {...props}>
    <Seo
      location="articles/printing-guide"
      title="seoArticlePrintingGuidePageTitle"
    />
    <Container fluid="lg" className="py-4 py-md-5">
      <h1>
        <FormattedMessage
          id="articlePrintingGuideTitle"
          defaultMessage="How to print the small booklet size"
        />
      </h1>
      <p>
        <FormattedMessage
          id="articlePrintingGuideParagraph1"
          defaultMessage="So, you got the small booklet size, and now you're wondering how you can print it. No worries, we're here to help!"
        />
      </p>

      <p>
        <FormattedMessage
          id="articlePrintingGuideParagraph2"
          defaultMessage="Please follow the steps below:"
        />
      </p>

      <ol>
        <FormattedMessage
          id="articlePrintingGuideParagraph3"
          defaultMessage="<li>Open the PDF (preferably with the Adobe Acrobat Reader app).</li><li>Click 'Print' to open the printer dialog.</li><li>Select the 'One page on one side' option.</li><li>Choose the 'Bind on the short edge' option.</li><li>Print the booklet.</li>"
          values={{ li: (chunks) => <li>{chunks}</li> }}
        />
      </ol>

      <p>
        <FormattedMessage
          id="articlePrintingGuideParagraph4"
          defaultMessage="If this didn't help, let us know by writing an email to <a>hello@yearcompass.com</a>, and we'll figure it out together."
          values={{
            a: (chunks) => <a href="mailto:hello@yearcompass.com">{chunks}</a>,
          }}
        />
      </p>

      <p>
        <FormattedMessage
          id="articlePrintingGuideParagraph5"
          defaultMessage="Have a nice day! 👋"
        />
      </p>
    </Container>
  </Layout>
);

export default PrintingGuidePage;
